import { ComponentSharedBackground, Maybe } from "@lib/generated/graphql";
import { slugify } from "@lib/routing";
import clsx from "clsx";
import { PropsWithChildren, forwardRef } from "react";
import Background from "../Background";

interface SectionData {
  background?: Maybe<ComponentSharedBackground>;
  navigationLink?: string;
}

interface SectionProps<T extends object | SectionData> {
  index?: number;
  section: T;
  className?: string;
  padding?: boolean;
  id?: string;
}

export const SECTION_PADDING = "px-s m:px-3xl";

const getSectionId = (section: SectionData) => {
  const link = section.navigationLink;
  return link && slugify(link);
};

const Section = forwardRef<
  HTMLElement,
  PropsWithChildren<SectionProps<object | SectionData>>
>(function Section(
  { id, index, section, children, className, padding = true },
  ref,
) {
  const idAttr = id || getSectionId(section);
  return (
    <Background
      ref={ref}
      id={idAttr}
      background={(section as SectionData).background}
      className={clsx("rounded-m scroll-m-5xl", className, {
        "!mt-2s": index === 0,
        [SECTION_PADDING]: padding,
      })}
    >
      {children}
    </Background>
  );
});

export default Section;
