import { Maybe, UploadFileEntityResponse } from "@lib/generated/graphql";
import clsx from "clsx";
import { FC, PropsWithChildren } from "react";
import AccordionItemBody from "./AccordionItemBody";
import AccordionItemTitle from "./AccordionItemTitle";

const AccordionItemContainer: FC<
  PropsWithChildren<{
    title: Maybe<string>;
    image?: Maybe<UploadFileEntityResponse>;
    className?: string;
    inverted?: boolean;
    serifClass?: string;
    onClick: VoidFunction;
    isActive: boolean;
    isTimerActive: boolean;
    autoOpenInterval?: Maybe<number>;
  }>
> = ({
  className,
  image,
  title,
  inverted,
  isActive,
  serifClass,
  onClick,
  isTimerActive,
  children,
  autoOpenInterval,
}) => {
  return (
    <div
      className={clsx("transition-all duration-300", className, {
        "rounded-s border border-neutral-50 relative overflow-hidden mb-2s":
          !className,
      })}
    >
      <AccordionItemTitle
        isTimerActive={isTimerActive}
        autoOpenInterval={autoOpenInterval}
        onClick={onClick}
        isActive={isActive}
        title={title}
        serifClass={serifClass}
        inverted={inverted}
      >
        {title}
      </AccordionItemTitle>
      <AccordionItemBody isActive={isActive} image={image}>
        {children}
      </AccordionItemBody>
    </div>
  );
};

export default AccordionItemContainer;
