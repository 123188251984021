import clsx from "clsx";
import React, { PropsWithChildren, forwardRef } from "react";
import { isInverted } from "../utilities";
import { BackgroundPattern, BackgroundVariant, SerifColor } from "./types";
import { getBackground, getBorder, getPatterns } from "./utilities";

export interface BackgroundType {
  variant?: BackgroundVariant | null;
  pattern?: BackgroundPattern | null;
  serifColor?: SerifColor | null;
  border?: boolean | null;
}
interface BackgroundProps {
  as?: React.ElementType;
  background: BackgroundType | null | undefined;
  className?: string;
  id?: string;
}

const Background = forwardRef<HTMLElement, PropsWithChildren<BackgroundProps>>(
  function Background({ background, as, className, children, id }, ref) {
    const backgroundColor = background?.variant;
    const pattern = background?.pattern;
    const bgClass = getBackground(backgroundColor);
    const patternClass = getPatterns(backgroundColor, pattern);
    const inverted = isInverted(background);

    const borderClass = background?.border ? getBorder(backgroundColor) : "";

    const Component = as ?? "section";

    return (
      <Component
        ref={ref}
        id={id}
        className={clsx(
          bgClass,
          patternClass,
          borderClass,
          {
            "text-neutral-0": inverted,
          },
          className
        )}
      >
        {children}
      </Component>
    );
  }
);

export default Background;
