import { UploadFileEntityResponse } from "@lib/generated/graphql";
import { FC } from "react";
import { SwitchTransition, Transition } from "react-transition-group";
import StrapiImage from "../../../../components/common/StrapiImage";

const defaultTransitionStyle = {
  transition: "transform 200ms ease, opacity 200ms ease",
};

const transitionStyles: any = {
  entering: {
    opacity: 0,
    transform: "translateY(calc(-10%))",
    ...defaultTransitionStyle,
  },
  entered: {
    opacity: 1,
    transform: "translateY(0)",
    ...defaultTransitionStyle,
  },
  exiting: {
    opacity: 0,
    transform: "translateY(calc(10%))",
    ...defaultTransitionStyle,
  },
  exited: {
    opacity: 0,
    ...defaultTransitionStyle,
  },
  timeout: 200,
};

const AccordionImage: FC<{ image: UploadFileEntityResponse }> = ({ image }) => {
  return (
    <SwitchTransition>
      <Transition timeout={200} key={image.data?.attributes?.url}>
        {(state) => (
          <div
            className="h-full ml-3s relative"
            style={{
              ...transitionStyles[state],
            }}
          >
            <StrapiImage
              sizes="700px"
              fill
              className="object-contain object-center"
              image={image}
            />
          </div>
        )}
      </Transition>
    </SwitchTransition>
  );
};

export default AccordionImage;
