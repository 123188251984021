import { Icon, Title } from "@justhome/react-ui";
import { Maybe } from "@lib/generated/graphql";
import clsx from "clsx";
import { FC, MouseEventHandler, PropsWithChildren, memo } from "react";

const AccordionProgressBar: FC<{
  isTimerActive: boolean;
  isActive: boolean;
  autoOpenInterval?: Maybe<number>;
  serifClass?: string;
}> = ({ isActive, isTimerActive, autoOpenInterval, serifClass }) => {
  const isShowProgressBar = isTimerActive && isActive;

  if (!isShowProgressBar) {
    return null;
  }

  const style = {
    animationPlayState: "running",
    animationTimingFunction: "linear",
    animationDuration: `${autoOpenInterval}ms`,
  };

  return (
    <div className="bg-primary-50 h-[6px] w-full">
      <div
        className={clsx(
          "transition-all h-[6px] bg-current",
          {
            "animate-progress": isShowProgressBar,
          },
          serifClass || "text-primary-800",
        )}
        style={style}
      />
    </div>
  );
};

const AccordionItemTitle: FC<
  PropsWithChildren<{
    title: Maybe<string>;
    isActive: boolean;
    isTimerActive: boolean;
    autoOpenInterval?: Maybe<number>;
    isClosed?: boolean;
    serifClass?: string;
    inverted?: boolean;
    onClick: MouseEventHandler;
  }>
> = memo(function AccordionItemTitle({
  isActive,
  title,
  isTimerActive,
  autoOpenInterval,
  serifClass,
  isClosed,
  inverted,
  onClick,
}) {
  return (
    <div>
      <div className="absolute top-none left-none right-none">
        <AccordionProgressBar
          isActive={isActive}
          isTimerActive={isTimerActive}
          autoOpenInterval={autoOpenInterval}
          serifClass={serifClass}
        />
      </div>
      <button
        className={clsx(
          "flex justify-between items-center px-3s cursor-pointer transition-all w-full py-3s focusable",
          {
            "hover:text-primary-825 active:text-primary-875": !inverted,
            "hover:text-neutral-50 active:text-neutral-100": inverted,
          },
        )}
        onClick={onClick}
      >
        <Title
          serifClass={serifClass}
          as="h3"
          inverted={inverted}
          className="text-h3 text-left pr-2xs"
        >
          {title}
        </Title>
        <Icon
          size="24"
          type="Down"
          className={clsx({
            "rotate-180": isActive && !isClosed,
          })}
        />
      </button>
    </div>
  );
});

export default AccordionItemTitle;
