import { Title } from "@justhome/react-ui";
import { Maybe } from "@lib/generated/graphql";
import { FC } from "react";

const AccordionHeader: FC<{
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  inverted?: boolean;
  serifClass?: string;
}> = ({ title, subtitle, inverted, serifClass }) => {
  return (
    <div>
      {title && (
        <Title inverted={inverted} serifClass={serifClass} className="text-d3">
          {title}
        </Title>
      )}
      {subtitle && <p className="text-body-1 mt-3s mb-m">{subtitle}</p>}
    </div>
  );
};

export default AccordionHeader;
