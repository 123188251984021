import clsx from "clsx";
import { BackgroundType } from "./Background";

export const isInverted = (background: BackgroundType | null | undefined) => {
  const variant = background?.variant;
  if (!variant) {
    return false;
  }
  return ["primary_800", "purple_900", "cadet_900", "cadet_850"].includes(
    variant,
  );
};

export const getSerifClass = (background?: BackgroundType | null) => {
  if (background?.serifColor) {
    const serifColors = {
      mindaro: "text-mindaro-325",
      primary: "text-primary-800",
      purple: "text-purple-725",
      rose: "text-rose-425",
      neutral: "text-neutral-0",
    };
    return serifColors[background.serifColor];
  }

  const variant = background?.variant;
  return clsx({
    "text-rose-425": variant === "rose_25",
    "text-mindaro-325": variant === "purple_900",
    "text-purple-725": variant === "purple_25",
  });
};
