import Section from "@components/common/Section";
import { getSerifClass, isInverted } from "@components/common/utilities";
import { ComponentSectionsAccordionV2 } from "@lib/generated/graphql";
import Accordion from "@modules/homepage/components/Accordion";
import clsx from "clsx";
import { FC } from "react";

const AnimatedAccordion: FC<{ data: ComponentSectionsAccordionV2 }> = ({
  data,
}) => {
  if (!data.items) {
    return null;
  }
  const { title, subTitle, autoOpenInterval, autoOpen, background } = data;

  const inverted = isInverted(background);
  const serifClass = getSerifClass(background);
  return (
    <Section
      section={data}
      className={clsx("px-s m:px-3xl", {
        "my-l m:my-[160px]": !background?.variant,
        "pb-m pt-3m m:py-3xl my-2s m:my-3s": background?.variant,
      })}
    >
      <Accordion
        items={data.items}
        serifClass={serifClass}
        inverted={inverted}
        autoOpenInterval={autoOpen ? autoOpenInterval : 0}
        title={title}
        subtitle={subTitle}
      />
    </Section>
  );
};

export default AnimatedAccordion;
