import { BackgroundPattern, BackgroundVariant } from "./types";

export const getBackground = (
  backgroundColor: BackgroundVariant | undefined | null
) => {
  if (!backgroundColor) {
    return "";
  }

  return `bg-${backgroundColor?.replace("_", "-")}`;
};

export const getBorder = (
  backgroundColor: BackgroundVariant | undefined | null
) => {
  if (!backgroundColor) {
    return "";
  }
  let border = "";
  if (backgroundColor.startsWith("primary")) {
    border = "border-primary-800";
  } else if (backgroundColor.startsWith("purple")) {
    border = "border-purple-375";
  } else if (backgroundColor.startsWith("cadet")) {
    border = "border-cadet-850";
  } else if (backgroundColor.startsWith("neutral")) {
    border = "border-neutral-150";
  }

  return `border ${border}`;
};

export const getPatterns = (
  variant: BackgroundVariant | undefined | null,
  pattern: BackgroundPattern | undefined | null
) => {
  if (!pattern) {
    return "";
  }

  const backgroundColor = variant || "";
  let suffix = "";

  if (
    [
      "primary_25",
      "neutral_0",
      "marigold_100",
      "marigold_50",
      "marigold_25",
      "purple_25",
      "cadet_25",
    ].includes(backgroundColor)
  ) {
    suffix = "-dark";
  } else if ("neutral_25" === backgroundColor) {
    suffix = "-primary";
  } else if ("rose_25" === backgroundColor) {
    suffix = "-rose";
  }

  return `bg-${pattern?.replace("_", "-")}${suffix}`;
};
