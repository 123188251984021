interface SerifBlock {
  type: "serif";
  text: string;
}

interface TextBlock {
  type: "text";
  text: string;
}

interface LinebreakBlock {
  type: "break";
}

type HighlightedText = (LinebreakBlock | SerifBlock | TextBlock)[];

const highlightedRegex = /^```/;
const linebreakRegex = /^<br\s*\/>/;

const parseHighlighedText = (input: string): HighlightedText => {
  let rest = input;
  let highlightedBlock = false;
  let text = "";
  const result: HighlightedText = [];

  while (rest.length) {
    const matchHighlight = rest.match(highlightedRegex);
    const matchLinebreak = rest.match(linebreakRegex);

    if (matchHighlight?.length) {
      if (highlightedBlock) {
        result.push({ text, type: "serif" });
      } else if (text) {
        result.push({ text, type: "text" });
      }
      highlightedBlock = !highlightedBlock;
      text = "";
      rest = rest.substring(3);
    } else if (matchLinebreak?.length) {
      if (text) {
        result.push({ text, type: "text" });
        text = "";
      }
      result.push({ type: "break" });
      rest = rest.substring(matchLinebreak[0].length);
    } else {
      text += rest.charAt(0);
      rest = rest.substring(1);
    }

    if (!rest && text) {
      result.push({ text, type: "text" });
    }
  }
  return result;
};

export default parseHighlighedText;
