import StrapiImage from "@components/common/StrapiImage";
import { Maybe, UploadFileEntityResponse } from "@lib/generated/graphql";
import { FC, PropsWithChildren, memo } from "react";
import AnimateHeight from "react-animate-height";

const AccordionItemBody: FC<
  PropsWithChildren<{
    isActive: boolean;
    image?: Maybe<UploadFileEntityResponse>;
  }>
> = memo(function AccordionItemBody({ isActive, children, image }) {
  return (
    <AnimateHeight
      className="overflow-hidden transition-all duration-500"
      duration={300}
      height={isActive ? "auto" : 0}
    >
      <div className="w-full px-3s py-s flex flex-row flex-wrap gap-3s m:gap-3m">
        <div className="max-w-[500px] min-w-[280px] flex-1">{children}</div>

        <StrapiImage
          loading="lazy"
          width="600"
          height="600"
          image={image}
          className="l:hidden object-contain w-auto min-w-[280px] flex-1"
        />
      </div>
    </AnimateHeight>
  );
});

export default AccordionItemBody;
