import React, { FC } from "react";
import TextHighlight from "../TextHighlight";

interface TitleProps {
  as?: React.ElementType;
  children?: string | null;
  className?: string;
  inverted?: boolean;
  serifClass?: string;
}

const Title: FC<TitleProps> = ({
  children,
  as,
  className = "text-d3",
  inverted,
  serifClass,
}) => {
  return (
    <TextHighlight
      as={as ?? "h2"}
      className={className}
      inverted={inverted}
      serifClass={serifClass}
      serif
    >
      {children}
    </TextHighlight>
  );
};

export default Title;
