"use client";
/* eslint-disable jsx-a11y/heading-has-content */
import clsx from "clsx";
import { ElementType, useMemo } from "react";
import Maybe from "../lib/Maybe";
import parseHighlighedText from "./parseHighlighedText";

const TextHighlight = ({
  children,
  className,
  inverted,
  serifClass,
  serif,
  as,
}: {
  children?: Maybe<string>;
  className?: string;
  inverted?: boolean;
  serifClass?: string;
  serif?: boolean;
  as?: ElementType;
}) => {
  const text = useMemo(() => {
    if (!children) {
      return null;
    }

    const parsed = parseHighlighedText(children);
    return parsed.map((part, index) => {
      if (part.type === "serif") {
        return (
          <span
            key={part.text}
            className={clsx(
              {
                "font-serif text-[108%] leading-[1]": serif,
                "text-primary-800": !serifClass && !inverted,
                "text-neutral-0": !serifClass && inverted,
              },
              serifClass,
            )}
          >
            {part.text}
          </span>
        );
      } else if (part.type === "break") {
        return <br key={index} />;
      }
      return part.text;
    });
  }, [children, inverted, serifClass, serif]);
  const Component = as || "span";
  return <Component className={className}>{text}</Component>;
};

export default TextHighlight;
