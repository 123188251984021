import {
  Maybe,
  PageEntityResponse,
  UploadFileEntityResponse,
} from "@lib/generated/graphql";
import clsx from "clsx";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import Button from "../../../../components/blocks/Button";
import AccordionHeader from "./AccordionHeader";
import AccordionImage from "./AccordionImage";
import AccordionItemContainer from "./AccordionItemContainer";

const isElementVisible = (target: HTMLDivElement | null) => {
  if (!target) {
    return false;
  }

  const targetPosition = {
    top: window.scrollY + target.getBoundingClientRect().top,
    bottom: window.scrollY + target.getBoundingClientRect().bottom,
  };

  const windowPosition = {
    top: window.scrollY,
    bottom: window.scrollY + document.documentElement.clientHeight,
  };

  return (
    targetPosition.bottom > windowPosition.top &&
    targetPosition.top < windowPosition.bottom
  );
};

export interface AccordionItem {
  id: string;
  image?: Maybe<UploadFileEntityResponse>;
  title: Maybe<string>;
  body?: Maybe<string>;
  href?: Maybe<string>;
  label?: Maybe<string>;
  trackingId?: Maybe<string>;
  page?: Maybe<PageEntityResponse>;
}

const Accordion: FC<{
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  autoOpenInterval?: Maybe<number>;
  className?: string;
  inverted?: boolean;
  serifClass?: string;
  items: Maybe<AccordionItem>[];
}> = ({
  className,
  title,
  subtitle,
  autoOpenInterval,
  inverted,
  serifClass,
  items,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(!!autoOpenInterval);
  const [isVisible, setIsVisible] = useState(false);
  const image = items[activeIndex]?.image;
  const timer = useRef<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const setNextActive = useCallback(() => {
    setActiveIndex((index) => (index + 1) % items.length);
  }, [items]);

  const handleStartTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    if (autoOpenInterval) {
      setIsTimerActive(true);
      timer.current = window.setInterval(setNextActive, autoOpenInterval);
    }
  }, [autoOpenInterval, setNextActive]);

  const handleStopTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    setIsTimerActive(false);
  }, []);

  const handleClickItem = useCallback(
    (index: number) => {
      handleStopTimer();
      setActiveIndex(index);
    },
    [handleStopTimer],
  );

  useEffect(() => {
    const handleScroll = () => {
      if (!autoOpenInterval) {
        return;
      }

      const newValue = isElementVisible(ref.current);

      if (isVisible && !newValue) {
        setIsVisible(false);
      } else if (!isVisible && newValue) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible, setIsVisible, autoOpenInterval]);

  useEffect(() => {
    if (isVisible) {
      handleStartTimer();
    } else {
      handleStopTimer();
    }
  }, [isVisible, handleStartTimer, handleStopTimer]);

  return (
    <div
      className={clsx(
        "l:grid grid-cols-12 gap items-center",
        {
          "text-neutral-0": inverted,
        },
        className,
      )}
      ref={ref}
    >
      <div className="m:col-span-5">
        <AccordionHeader
          serifClass={serifClass}
          inverted={inverted}
          title={title}
          subtitle={subtitle}
        />
        <div onMouseEnter={handleStopTimer} onMouseLeave={handleStartTimer}>
          {items.map(
            (item, index) =>
              item && (
                <AccordionItemContainer
                  autoOpenInterval={autoOpenInterval}
                  isTimerActive={isTimerActive}
                  onClick={() => handleClickItem(index)}
                  key={item.title}
                  inverted={inverted}
                  {...item}
                  isActive={index === activeIndex}
                >
                  {item.body}
                  {(item.href || item.page) && item.label && (
                    <Button
                      id={item.id}
                      trackingId={item.trackingId}
                      size="md"
                      variant={inverted ? "ghost_inverted" : "ghost"}
                      paddingX={false}
                      iconEnd="Right"
                      href={item.href}
                      page={item.page}
                      className="mt-2s"
                      label={item.label}
                    />
                  )}
                </AccordionItemContainer>
              ),
          )}
        </div>
      </div>
      <div className="col-span-6 col-start-7 hidden l:block h-full">
        {image && <AccordionImage image={image} />}
      </div>
    </div>
  );
};

export default Accordion;
